import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/analytics';

const config = {
  apiKey: "AIzaSyAduoawedf1Rlc0Dxv9V_SNnwe75Yk6Wv4",
  authDomain: "drinkmix-2650a.firebaseapp.com",
  databaseURL: "https://drinkmix-2650a.firebaseio.com",
  projectId: "drinkmix-2650a",
  storageBucket: "drinkmix-2650a.appspot.com",
  messagingSenderId: "977612102986",
  appId: "1:977612102986:web:8b14b8fc9945517595f5b3",
  measurementId: "G-G9MHTF7MFF"
};

let instance;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance)
      return instance;
    instance = firebase.initializeApp(config);
    firebase.analytics();
    return instance;
  }

  return null;
}

// const displayName = activeUser.displayName;
// const email = activeUser.email;
// const emailVerified = activeUser.emailVerified;
// const photoURL = activeUser.photoURL;
// const isAnonymous = activeUser.isAnonymous;
// const uid = activeUser.uid;
// const providerData = activeUser.providerData;
