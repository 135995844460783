/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"
import "./layout.css"

import Drift from 'react-driftjs'
import { hotjar } from 'react-hotjar';

// import FullStory from 'react-fullstory';

// const ORG_ID = 'QYBKB';

// <FullStory org={ORG_ID} />

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <main><div style={{height: '99vh'}}><Drift appId="cumyh5vwesef"/>{children}</div></main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

if (typeof window !== `undefined`) {
  hotjar.initialize(1649069);
}

export default Layout
