import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Chip from '@material-ui/core/Chip';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import MenuButton from '../components/MenuButton';

import {navigate} from "gatsby"

import getFirebase from '../utils/firebase';

const firebase = getFirebase();

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // backgroundColor: 'red',
    width: '100%',
    zIndex: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

function ElevationScroll(props) {
  const {children, window} = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window
      ? window()
      : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger
      ? 4
      : 0
  });
}

export default function MenuAppBar(props) {
  const classes = useStyles();
  const [activeUser, setActiveUser] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!firebase)
      return;
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          // alert('User is signed in')
          // setIsSignedIn(!!user);
          setActiveUser(user);
        } else {
          // setIsSignedIn(undefined)
          setActiveUser(undefined);
        }
      });
  });

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClick = () => {
    navigate('/profile')
  };

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut();
    navigate('/signedout');
  };

  const handleSignIn = () => {
    navigate('/signin')
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Conditionals

  let profileMenuItems;

  if (activeUser) {
    profileMenuItems = (<div>
      <MenuItem onClick={handleProfileClick}>My Profile</MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </div>)
  } else {
    profileMenuItems = (<div>
      <MenuItem onClick={handleSignIn}>Sign In</MenuItem>
    </div>)
  }

  let profileIcon;

  if (activeUser) {
    profileIcon = <img src={activeUser.photoURL} alt='Profile' width='40px' height='40px' style={{
        borderRadius: '150px',
        margin: '0'
      }}/>
  } else {
    profileIcon = <AccountCircle style={{
        fontSize: 42
      }}/>
  }

  function goHome() {
    navigate('/home')
  }

  // Render

  return (<div className={classes.root}>
    <ElevationScroll {...props}>
      <AppBar color='inherit' elevation='0'>
        <Toolbar style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            backgroundColor: 'white'
          }}>
          <MenuButton handleRandomClick={props.handleRandomClick} setCardShown={props.setCardShown}/>
          <div>
            <Typography variant="h6" onClick={goHome} className={classes.title} style={{
                cursor: 'pointer'
              }}>
              DrinkMix
            </Typography>
          </div>
          <Chip onClick={goHome} label="Beta" color='secondary' style={{
              marginLeft: 10,
              cursor: 'pointer'
            }}/>
          <Typography variant="h6" className={classes.title}></Typography>
          <div>
            <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit" style={{
                padding: '5px',
                margin: 0
              }}>
              {profileIcon}
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }} keepMounted="keepMounted" transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }} open={open} onClose={handleClose}>
              {profileMenuItems}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  </div>);
}
