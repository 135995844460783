import React from 'react';
import {navigate} from "gatsby"

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import GroupIcon from '@material-ui/icons/Group';

// Icons
// import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import CasinoIcon from '@material-ui/icons/Casino';
// import ExploreIcon from '@material-ui/icons/Explore';
// import BookIcon from '@material-ui/icons/Book';
// import FaceIcon from '@material-ui/icons/Face';
// import LocalBarIcon from '@material-ui/icons/LocalBar';
// import CodeIcon from '@material-ui/icons/Code';
// import SendIcon from '@material-ui/icons/Send';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import FavoriteIcon from '@material-ui/icons/Favorite';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import CreateIcon from '@material-ui/icons/Create';
import BookIcon from '@material-ui/icons/Book';

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  list: {
    width: 250
  }
}));

// Component: AppDrawerButton
export default function MenuButton(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({top: false, left: false, bottom: false, right: false});

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({
      ...state,
      [side]: open
    });
  };

  const handleHomeClick = () => {
    navigate("/home");
    if (props.setCardShown) {
      props.setCardShown(false);
    }
  };

  const handleRandomClick = () => {
    navigate("/random");
  };

  // <ListItem button key='About' onClick={() => navigate('/catalog')}>
  //   <ListItemIcon><LocalBarIcon/></ListItemIcon>
  //   <ListItemText primary='About'/>
  // </ListItem>

  // <ListItem button key='Share'>
  //   <ListItemIcon><SendIcon/></ListItemIcon>
  //   <ListItemText primary='Share App'/>
  // </ListItem>

  const sideList = side => (<div className={classes.list} role="presentation" onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)}>
    <List>
      <ListItem button="button" key='Home' onClick={handleHomeClick}>
        <ListItemIcon><HomeIcon/></ListItemIcon>
        <ListItemText primary='Home'/>
      </ListItem>
    </List>
    <Divider/>
    <List>
      <ListItem button="button" key='Classics' onClick={() => navigate('/classics')}>
        <ListItemIcon><LocalBarIcon/></ListItemIcon>
        <ListItemText primary='Classics'/>
      </ListItem>
      <ListItem button="button" key='Community' onClick={() => navigate('/community')}>
        <ListItemIcon><GroupIcon/></ListItemIcon>
        <ListItemText primary='Community'/>
      </ListItem>
      <ListItem button="button" key='Favourites' onClick={() => navigate('/favourites')}>
        <ListItemIcon><FavoriteIcon/></ListItemIcon>
        <ListItemText primary='Favourites'/>
      </ListItem>
    </List>
    <Divider/>
    <List>
      <ListItem button="button" key='Random' onClick={handleRandomClick}>
        <ListItemIcon><CasinoIcon/></ListItemIcon>
        <ListItemText primary='Random'/>
      </ListItem>
    </List>
    <Divider/>
    <List>
      <ListItem button="button" key='My Profile' onClick={() => navigate('/profile')}>
        <ListItemIcon><AccountCircleIcon/></ListItemIcon>
        <ListItemText primary='Profile'/>
      </ListItem>
      <ListItem button="button" key='Create' onClick={() => navigate('/myrecipes')}>
        <ListItemIcon><BookIcon/></ListItemIcon>
        <ListItemText primary='My Recipes'/>
      </ListItem>
    </List>
  </div>);

  return (<div>
    <IconButton onClick={toggleDrawer('left', true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
      <MenuIcon/>
    </IconButton>
    <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
      {sideList('left')}
    </Drawer>
  </div>);
}
